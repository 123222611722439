<template>
  <div>
    <b-modal
      id="modal-input-barang"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <div>
        <b-tabs content-class="mt-3" fill>
          <b-tab title="Barang">
            <b-container fluid>
              <b-row>
                <b-col>
                  <b-form>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Nama Barang <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :state="checkIfValid('nama_barang')"
                        type="text"
                        v-model="$v.is_data.nama_barang.$model"
                        
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Kode Produk <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :state="checkIfValid('kode_produk')"
                        type="text"
                        v-model="$v.is_data.kode_produk.$model"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Kode DPHO
                      </template>
                      <b-form-input
                        type="text"
                        v-model="is_data.kode_dpho"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Kelas Terapi
                      </template>
                      <multiselect
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="-- Pilih --"
                        label="text"
                        size="sm"
                        v-model="$v.is_data.ms_kelas_terapi_id.$model"
                        :options="option_kelas_terapi"
                        :state="checkIfValid('ms_kelas_terapi_id')"
                      ></multiselect>
                    </b-form-group>
                    <!-- <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Golongan Barang <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        v-model="$v.is_data.ms_golongan_barang_id.$model"
                        :options="option_golongan_barang"
                        :state="checkIfValid('ms_golongan_barang_id')"
                      ></b-form-select>
                    </b-form-group> -->
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Produsen 
                      </template>
                      <b-form-select
                        v-model="$v.is_data.ms_produsen_id.$model"
                        :options="option_produsen"
                        :state="checkIfValid('ms_produsen_id')"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Jenis Barang <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        v-model="$v.is_data.ms_jenis_barang_id.$model"
                        :options="option_jenis_barang"
                        :state="checkIfValid('ms_jenis_barang_id')"
                      ></b-form-select>
                    </b-form-group>
                    <!-- <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Obat BPJS
                      </template>
                      <multiselect
                        :options="listObatBpjs"
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="-- Pilih --"
                        label="text"
                        size="sm"
                        v-model="$v.is_data.kode_dpho.$model"
                      ></multiselect>
                    </b-form-group> -->
                  </b-form>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Satuan">
            <b-container class="mb-2">
              <!-- <b-row align-v="center">
                <b-col>
                  <h5 class="m-0">
                    <strong>Satuan Utama</strong>
                  </h5>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols="12">
                  <div v-for="(v, i) in $v.is_data.bulk_satuan.$each.$iter" :key="i">
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Satuan {{ i + 1 }} <span class="text-danger">*</span>
                      </template>
                      <Multiselect
                        v-model="v.ms_satuan_barang_id.$model"
                        :options="option_satuan_barang"
                        :state="checkIfValid('bulk_satuan', 'ms_satuan_barang_id')"
                        label="nama_satuan"
                        track-by="ms_satuan_barang_id"
                        placeholder="-- Pilih Satuan --"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                      />
                    </b-form-group>
                    <b-form-group label="Satuan Utama" label-cols-md="3">
                      <b-form-select
                        v-model="v.is_satuan_utama.$model"
                        :disabled="checkValue(i)"
                      >
                        <b-form-select-option :value="true">Ya</b-form-select-option>
                        <b-form-select-option :value="false">Tidak</b-form-select-option>
                      </b-form-select>
                      <!-- <b-form-checkbox
                        :disabled="i > 0"
                        v-model="v.is_satuan_utama.$model"
                        :value="true"
                        :unchecked-value="false"
                        class="pt-2"
                      ></b-form-checkbox> -->
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Konversi <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        type="number"
                        v-model="v.konversi.$model"
                        :state="checkIfValid('bulk_satuan', 'konversi')"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Harga 1 <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        type="number"
                        v-model="v.harga_1.$model"
                        :state="checkIfValid('bulk_satuan', 'harga_1')"
                        
                      ></b-form-input>
                    </b-form-group>
                    <!-- <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Harga 2 <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        type="number"
                        v-model="v.harga_2.$model"
                        :state="checkIfValid('bulk_satuan', 'harga_2')"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Harga 3 <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        type="number"
                        v-model="v.harga_3.$model"
                        :state="checkIfValid('bulk_satuan', 'harga_3')"
                      ></b-form-input>
                    </b-form-group> -->
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Komposisi">
            <b-container>
              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button
                    class="mb-2"
                    variant="outline-success"
                    @click="tambahDataKomposisi()"
                  >
                    Tambah Komposisi
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <!-- <b-form inline> -->

                  <div v-for="(v, i) in $v.is_data.bulk_komposisi.$each.$iter" :key="i">
                    <!-- <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Komposisi {{ Number(i) + 1 }}<span class="text-danger">*</span>
                      </template>
                      <b-input-group>
                        <Multiselect
                          v-model="v.ms_komposisi_barang_id.$model"
                          :options="option_komposisi_barang"
                          :state="
                            checkIfValid('bulk_komposisi', 'ms_komposisi_barang_id')
                          "
                          label="nama_komposisi"
                          track-by="ms_komposisi_barang_id"
                          placeholder="-- Pilih Satuan --"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                        ></Multiselect>

                        <b-input-group-append>
                          <b-button
                            :disabled="is_data.bulk_komposisi.length == 1"
                            @click="hapusDataSatuan(i)"
                            >X</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group> -->
                    <b-row>
                      <b-col cols="3" class="display-komposisi">
                        <label for="komposisi-barang">Komposisi</label>
                      </b-col>
                      <b-col class="display-komposisi">
                        <b-col>
                          <Multiselect
                            id="komposisi-barang"
                            v-model="v.ms_komposisi_barang_id.$model"
                            :options="option_komposisi_barang"
                            :state="
                              checkIfValid('bulk_komposisi', 'ms_komposisi_barang_id')
                            "
                            label="nama_komposisi"
                            track-by="ms_komposisi_barang_id"
                            placeholder="-- Pilih Satuan --"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                          ></Multiselect>
                        </b-col>
                        <b-col cols="auto">
                          <b-button
                            :disabled="is_data.bulk_komposisi.length == 1"
                            @click="hapusDataSatuan(i)"
                          >
                            X
                          </b-button>
                        </b-col>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- </b-form> -->
                </b-col>
              </b-row>
            </b-container>

          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-barang')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: [
    "fileName",
    "option_kelas_terapi",
    // "option_golongan_barang",
    "option_produsen",
    "option_jenis_barang",
    "option_satuan_barang",
    "option_komposisi_barang",
  ],
  data() {
    return {
      is_data: {
        nama_barang: null,
        kode_produk: null,
        ms_kelas_terapi_id: null,
        // ms_golongan_barang_id: null,
        ms_produsen_id: null,
        ms_jenis_barang_id: null,
        kode_dpho: null,
        bulk_satuan: [
          {
            ms_satuan_barang_id: "",
            is_satuan_utama: true,
            konversi: null,
            harga_1: null,
          },
        ],
        bulk_komposisi: [
          {
            ms_komposisi_barang_id: "",
          },
        ],
      },
      komposisi_obj: {
        ms_komposisi_barang_id: "",
      },
      listObatBpjs: [],
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_barang: { required },
      kode_produk: { required },
      ms_kelas_terapi_id: {  },
      // ms_golongan_barang_id: { required },
      ms_produsen_id: {  },
      ms_jenis_barang_id: { required },
      kode_dpho: {  },
      bulk_satuan: {
        $each: {
          ms_satuan_barang_id: { required },
          is_satuan_utama: { required },
          konversi: { required },
          harga_1: { required },
          // harga_2: { required },
          // harga_3: { required },
        },
      },
      bulk_komposisi: {
        $each: {
          ms_komposisi_barang_id: { },
        },
      },
    },
  },
  mounted() {
    // const vm = this
    // vm.getObatBPJS()
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    checkValue(i) {
      return i > 0 ? false : true;
    },
    // setAllPrice(ev, idx) {
    //   this.is_data.bulk_satuan[idx].harga_2 = ev;
    //   this.is_data.bulk_satuan[idx].harga_3 = ev;
    // },
    // async getObatBPJS(){
    //   const vm = this
    //   vm.busy = false
    //   try {
    //     console.log('get data')
    //     const obatBpjs = await vm.$axios.post('/integrasi_pcare/get_obat', {kode_nama_dpho: vm.is_data.nama_barang})
    //     vm.listObatBpjs = obatBpjs.data.status == 200 ? obatBpjs.data.data.map(x => {
    //       return {
    //         ...x,
    //         text: `${x.kdObat} - ${x.nmObat}`
    //       }
    //     }) : []
    //     console.log('obatBpjs', obatBpjs)
    //   } catch (error) {
    //     vm.$emit("alertFromChild", { variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
    //     console.log(error)
    //   } finally {
    //     vm.busy = false
    //   }
    // },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.is_data.bulk_satuan.forEach((el) => {
        el.ms_satuan_barang_id = el.ms_satuan_barang_id.ms_satuan_barang_id;
      });
      vm.is_data.bulk_komposisi.forEach((el) => {
        el.ms_komposisi_barang_id = el.ms_komposisi_barang_id.ms_komposisi_barang_id;
      });
      if(vm.is_data.ms_kelas_terapi_id){
        vm.is_data.ms_kelas_terapi_id = vm.is_data.ms_kelas_terapi_id.value
      }
      vm.$axios
        .post(`/${vm.file_name}/register`, {
            ...vm.is_data,
            // kode_dpho: vm.is_data.kode_dpho ? vm.is_data.kode_dpho.kdObat : null,
          })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_barang: null,
        kode_produk: null,
        ms_kelas_terapi_id: null,
        // ms_golongan_barang_id: null,
        ms_produsen_id: null,
        ms_jenis_barang_id: null,
        bulk_satuan: [
          {
            ms_satuan_barang_id: "",
            is_satuan_utama: true,
            konversi: null,
            harga_1: null,
            harga_2: null,
            harga_3: null,
          },
        ],
        bulk_komposisi: [
          {
            ms_komposisi_barang_id: "",
          },
        ],
      };
    },
    tambahDataKomposisi() {
      this.komposisi_obj = {
        ms_komposisi_barang_id: "",
      };
      this.is_data.bulk_komposisi.push(this.komposisi_obj);
    },
    hapusDataSatuan(index) {
      this.is_data.bulk_komposisi.splice(index, 1);
    },
  },
  components: { Multiselect },
};
</script>

<style scoped>
.display-komposisi {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 1rem;
  align-items: center;
}
</style>
