<template>
  <div>
    <b-modal
      id="modal-edit-barang"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <!-- @show="showModal()" -->
      <div v-if="is_data">
        <b-tabs content-class="mt-3" fill>
          <b-tab title="Barang">
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Nama Barang <span class="text-danger">*</span>
              </template>
              <b-form-input
                :state="checkIfValid('nama_barang')"
                type="text"
                v-model="$v.is_data.nama_barang.$model"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Kode Produk <span class="text-danger">*</span>
              </template>
              <b-form-input
                :state="checkIfValid('kode_produk')"
                type="text"
                v-model="$v.is_data.kode_produk.$model"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Kode DPHO
              </template>
              <b-form-input
                type="text"
                v-model="is_data.kode_dpho"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Kelas Terapi
              </template>
              <multiselect
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="-- Pilih --"
                label="text"
                size="sm"
                v-model="is_data.ms_kelas_terapi_id"
                :options="option_kelas_terapi"
              ></multiselect>
            </b-form-group>
            <!-- <b-form-group label-cols-md="3">
              <template v-slot:label>
                Golongan Barang <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="$v.is_data.ms_golongan_barang_id.$model"
                :options="option_golongan_barang"
                :state="checkIfValid('ms_golongan_barang_id')"
              ></b-form-select>
            </b-form-group> -->
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Produsen 
              </template>
              <b-form-select
                v-model="$v.is_data.ms_produsen_id.$model"
                :options="option_produsen"
                :state="checkIfValid('ms_produsen_id')"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Jenis Barang <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="$v.is_data.ms_jenis_barang_id.$model"
                :options="option_jenis_barang"
                :state="checkIfValid('ms_jenis_barang_id')"
              ></b-form-select>
            </b-form-group>
            <!-- <b-form-group label-cols-md="3">
              <template v-slot:label>
                Obat BPJS
              </template>
              <multiselect
                :options="listObatBpjs"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="-- Pilih --"
                label="text"
                size="sm"
                v-model="$v.is_data.kode_dpho.$model"
              ></multiselect>
            </b-form-group> -->
          </b-tab>
          <b-tab title="Satuan">
            <b-container fluid class="pr-0 pl-0">
              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button
                    class="my-3"
                    variant="outline-success"
                    @click="tambahDataSatuan('satuan', true)"
                  >
                    Tambah Satuan
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
            <b-card v-for="(v, i) in $v.is_data.pool_satuan_barang.$each.$iter" :key="i">
              <b-container class="mb-2 pr-0 pl-0">
                <b-row align-v="center">
                  <b-col>
                    <h5 class="m-0">
                      <strong>Satuan {{ Number(i) + 1 }}</strong>
                    </h5>
                  </b-col>

                  <b-col cols="auto">
                    <b-button size="sm" variant="warning" @click="editDataSatuan(v, i)">
                      Edit
                    </b-button>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      :disabled="v.is_satuan_utama.$model"
                      size="sm"
                      variant="danger"
                      @click="hapusDataSatuan(v, i)"
                    >
                      Hapus
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Satuan <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-select
                    disabled
                    v-model="v.ms_satuan_barang_id.$model"
                    :options="option_satuan_barang"
                  ></b-form-select>
                  <b-input-group-append v-if="v.is_satuan_utama.$model">
                    <b-button disabled>Satuan Utama</b-button>
                    <b-input-group-text>
                      <b-form-checkbox
                        disabled
                        v-model="v.is_satuan_utama.$model"
                        :value="true"
                        :unchecked-value="false"
                        class="mr-n2"
                      ></b-form-checkbox>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Konversi <span class="text-danger">*</span>
                </template>
                <b-form-input
                  readonly
                  type="number"
                  v-model="v.konversi.$model"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Harga 1 <span class="text-danger">*</span>
                </template>
                <b-form-input
                  readonly
                  type="number"
                  v-model="v.harga_1.$model"
                ></b-form-input>
              </b-form-group>
              <!-- <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Harga 2 <span class="text-danger">*</span>
                </template>
                <b-form-input
                  readonly
                  type="number"
                  v-model="v.harga_2.$model"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Harga 3 <span class="text-danger">*</span>
                </template>
                <b-form-input
                  readonly
                  type="number"
                  v-model="v.harga_3.$model"
                ></b-form-input>
              </b-form-group> -->
            </b-card>
            <b-button class="my-3" variant="info" @click="simpanSatuanBarang">
              Konfirmasi 1
            </b-button>
          </b-tab>

          <b-tab title="Komposisi">
            <b-container>
              <b-row align-h="end">
                <b-col cols="auto">
                  <b-button
                    class="my-3"
                    variant="outline-success"
                    @click="tambahDataKomposisi()"
                  >
                    Tambah Komposisi
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div v-for="(v, i) in is_data.pool_komposisi" :key="i">
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Komposisi {{ Number(i) + 1 }}
                      </template>
                      <b-input-group>
                        <b-form-select
                          disabled
                          v-model="v.ms_komposisi_barang_id"
                          :options="option_komposisi_barang"
                          :state="
                            checkIfValid('pool_komposisi', 'ms_komposisi_barang_id')
                          "
                        ></b-form-select>

                        <b-input-group-append>
                          <b-button
                            :disabled="is_data.pool_komposisi.length == 1"
                            @click="hapusDataKomposisi(v)"
                          >
                            X
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-barang')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>

    <modal-satuan-input
      :fileName="file_satuan"
      :satuan_barang="satuan_barang"
      :option_satuan_barang="option_satuan_barang"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
    ></modal-satuan-input>
    <modal-satuan-edit
      :fileName="file_satuan"
      :data_edit="data_satuan"
      :option_satuan_barang="option_satuan_barang"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
    ></modal-satuan-edit>
    <modal-satuan-delete
      :fileName="file_satuan"
      :data_delete="data_delete"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
    ></modal-satuan-delete>

    <modal-komposisi-input
      :fileName="file_komposisi"
      :komposisi_barang="komposisi_barang"
      :option_komposisi_barang="option_komposisi_barang"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
      @errorFromChild="triggerAlert($event)"
    ></modal-komposisi-input>
    <modal-komposisi-edit
      :fileName="file_komposisi"
      :data_edit="data_komposisi"
      :option_komposisi_barang="option_komposisi_barang"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
    ></modal-komposisi-edit>
    <modal-komposisi-delete
      :fileName="file_komposisi"
      :data_delete="data_delete"
      @alertFromChild="triggerAlert($event), getDetailsById(is_data.ms_barang_id)"
    ></modal-komposisi-delete>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

import ModalSatuanInput from "./modalSatuanInput.vue";
import ModalSatuanEdit from "./modalSatuanEdit.vue";
import ModalSatuanDelete from "./modalSatuanDelete.vue";

import ModalKomposisiInput from "./modalKomposisiInput.vue";
import ModalKomposisiEdit from "./modalKomposisiEdit.vue";
import ModalKomposisiDelete from "./modalKomposisiDelete.vue";

export default {
  name: "modalEdit",
  props: [
    "fileName",
    "data_edit",
    "option_kelas_terapi",
    // "option_golongan_barang",
    "option_produsen",
    "option_jenis_barang",
    "option_satuan_barang",
    "option_komposisi_barang",
  ],
  components: {
    // Multiselect,
    ModalSatuanInput,
    ModalSatuanEdit,
    ModalSatuanDelete,

    ModalKomposisiInput,
    ModalKomposisiEdit,
    ModalKomposisiDelete,
  },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      data_satuan: null,
      data_komposisi: null,
      data_delete: null,
      listObatBpjs: [],
      satuan_barang: {},
      komposisi_barang: {},
      komposisi_obj: {
        ms_komposisi_barang_id: "",
      },
      satuan_barang_obj: {
        is_satuan_utama: false,
        konversi: 0,
        harga_1: 0,
        // harga_2: 0,
        // harga_3: 0,
        ms_satuan_barang_id: "",
      },
      file_name: this.fileName,
      file_satuan: "pool_satuan_barang",
      file_komposisi: "pool_komposisi",
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      for (let i = 0; i < this.option_kelas_terapi.length; i++) {
        let x = this.option_kelas_terapi[i];
        if(x.ms_kelas_terapi_id == this.is_data.ms_kelas_terapi_id){
          this.is_data.ms_kelas_terapi_id = x
        }
      }
      // this.showModal()
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_barang: { required },
      kode_produk: { required },
      ms_kelas_terapi_id: {  },
      // ms_golongan_barang_id: { required },
      ms_produsen_id: {  },
      ms_jenis_barang_id: { required },
      kode_dpho: {  },
      pool_satuan_barang: {
        $each: {
          ms_satuan_barang_id: { required },
          is_satuan_utama: { required },
          konversi: { required },
          harga_1: { required },
          // harga_2: { required },
          // harga_3: { required },
        },
      },
      pool_komposisi: {
        $each: {
          ms_komposisi_barang_id: {  },
        },
      },
    },
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    // async showModal(){
    //   const vm = this
    //   vm.busy = false
    //   try {
    //     await vm.getObatBPJS()
    //     vm.is_data.kode_dpho = vm.$findKey(vm.listObatBpjs, vm.is_data.kode_dpho, true, 'kdObat')
    //   } catch (error) {
    //     vm.$emit("alertFromChild", { variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
    //     console.log(error)
    //   } finally {
    //     vm.busy = false
    //   }
    // },
    // async getObatBPJS(){
    //   const vm = this
    //   vm.busy = false
    //   try {
    //     console.log('get data')
    //     const obatBpjs = await vm.$axios.post('/integrasi_pcare/get_obat', {kode_nama_dpho: vm.is_data.nama_barang})
    //     vm.listObatBpjs = obatBpjs.data.status == 200 ? obatBpjs.data.data.map(x => {
    //       return {
    //         ...x,
    //         text: `${x.kdObat} - ${x.nmObat}`
    //       }
    //     }) : []
    //     console.log('obatBpjs', obatBpjs)
    //   } catch (error) {
    //     vm.$emit("alertFromChild", { variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
    //     console.log(error)
    //   } finally {
    //     vm.busy = false
    //   }
    // },
    async getDetailsById(data) {
      const vm = this;
      try {
        const res = await vm.$axios(`/${vm.file_name}/details_by_id/${data}`);
        if (res.data.status == 200) {
          vm.is_data = res.data.data[0];
          vm.is_data.pool_satuan_barang.sort(
            (a, b) => b.is_satuan_utama - a.is_satuan_utama
          );
          vm.data_satuan = null;
          vm.data_komposisi = null
          console.log("get", vm.is_data.pool_satuan_barang);
        }
      } catch (err) {
        console.log(err);
        vm.button = "Simpan";
        vm.busy = false;
        vm.$emit("alertFromChild", {
          variant: "danger",
          msg: "TERJADI KESALAHAN PADA SERVER",
          showing: true,
        });
      }
    },
    tambahDataSatuan(key, add = false) {
      let x = this.is_data.pool_satuan_barang.find((o) => o.is_satuan_utama === true);
      this.satuan_barang = {
        barang_id: this.is_data.ms_barang_id,
        harga1: x.harga_1,
      };
      if (this.satuan_barang) {
        console.log(this.satuan_barang);
        this.$bvModal.show("modal-input-satuan");
      }
    },
    editDataSatuan(val, idx) {
      this.data_satuan = this.is_data.pool_satuan_barang.find(
        (o) => o.ms_satuan_barang_id == val.ms_satuan_barang_id.$model
      );
      this.$bvModal.show("modal-edit-satuan");
    },
    hapusDataSatuan(val, idx) {
      console.log(val.is_satuan_utama.$model);
      this.data_satuan = this.is_data.pool_satuan_barang.find(
        (o) => o.ms_satuan_barang_id == val.ms_satuan_barang_id.$model
      );
      this.$bvModal.show("modal-delete-satuan");
    },
    tambahDataKomposisi() {
      this.komposisi_barang = {
        barang_id: this.is_data.ms_barang_id,
      };
      if (this.komposisi_barang.barang_id) {
        this.$bvModal.show("modal-input-komposisi");
      }
    },
    hapusDataKomposisi(val) {
      this.data_delete = {
        pool_komposisi_id: val.pool_komposisi_id
      }
      if (this.data_delete.pool_komposisi_id) {
        this.$bvModal.show("modal-delete-komposisi");
      }
    },
    async simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      // console.log(vm.is_data);
      for (const key in vm.is_data) {
        if (Object.hasOwnProperty.call(vm.is_data, key)) {
          if (key === "id") {
            vm.is_data[key] = vm.is_data.ms_barang_id
          }
        }
      }
      if(vm.is_data.ms_kelas_terapi_id){
        vm.is_data.ms_kelas_terapi_id = vm.is_data.ms_kelas_terapi_id.value
      }
      console.log(vm.is_data);
      // try {
      //   let res = await vm.$axios.post(`/${vm.file_name}/update`, {
      //       ...vm.is_data,
      //       // kode_dpho: vm.is_data.kode_dpho ? vm.is_data.kode_dpho.kdObat : null, 
      //     })
      //   if (res.data.message == "sukses") {
      //     vm.button = "Simpan";
      //     vm.busy = false;
      //     vm.$v.$reset();
      //     vm.$emit("alertFromChild", {
      //       variant: "success",
      //       msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
      //       showing: true,
      //     });
      //     vm.$bvModal.hide("modal-edit-barang");
      //     vm.resetModal();
      //   } else {
      //     vm.button = "Simpan";
      //     vm.busy = false;
      //     vm.$emit("alertFromChild", {
      //       variant: "danger",
      //       msg: _.toUpper(res.data.message),
      //       showing: true,
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      //   vm.$emit("alertFromChild", {
      //     variant: "danger",
      //     msg: "TERJADI KESALAHAN PADA SERVER",
      //     showing: true,
      //   });
      // } finally {
      //   vm.button = "Simpan";
      //   vm.busy = false;
      // }
      vm.$axios
        .post(`/${vm.file_name}/update`, {
            ...vm.is_data,
            // kode_dpho: vm.is_data.kode_dpho ? vm.is_data.kode_dpho.kdObat : null, 
          })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-barang");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        })
        .finally(() => {
          vm.button = "Simpan";
          vm.busy = false;
        })
    },
    // simpan satuan barang
    simpanSatuanBarang() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-barang");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    // simpan komposisi barang
    simpanKomposisiBarang() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-barang");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_barang: null,
        kode_produk: null,
        ms_kelas_terapi_id: null,
        // ms_golongan_barang_id: null,
        ms_produsen_id: null,
        ms_jenis_barang_id: null,
        pool_satuan_barang: [
          {
            ms_satuan_barang_id: "",
            is_satuan_utama: true,
            konversi: null,
            harga_1: null,
            harga_2: null,
            harga_3: null,
          },
        ],
        pool_komposisi: [
          {
            ms_komposisi_barang_id: "",
          },
        ],
      };
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
