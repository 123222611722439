<template>
  <div>
    <b-modal
      id="modal-edit-komposisi"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <div v-if="is_data">
        <b-form-group label-cols-md="3">
          <template v-slot:label> Satuan <span class="text-danger">*</span> </template>
          <b-input-group>
            <b-form-select
              v-model="$v.is_data.ms_satuan_barang_id.$model"
              :options="option_satuan_barang"
              :state="checkIfValid('ms_satuan_barang_id')"
            ></b-form-select>
            <b-input-group-append v-if="is_data.is_satuan_utama">
              <b-button disabled>Satuan Utama</b-button>
              <b-input-group-text>
                <b-form-checkbox
                  :disabled="true"
                  v-model="$v.is_data.is_satuan_utama.$model"
                  :value="true"
                  :unchecked-value="false"
                  class="mr-n2"
                  :state="checkIfValid('is_satuan_utama')"
                ></b-form-checkbox>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
                <template v-slot:label>
                  Satuan Utama <span class="text-danger">*</span>
                </template>
                <b-form-checkbox
                  type="text"
                  v-model="is_data_satuan_ob.is_satuan_utama"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
              </b-form-group> -->
        <b-form-group label-cols-md="3">
          <template v-slot:label> Konversi <span class="text-danger">*</span> </template>
          <b-form-input
            type="number"
            v-model="$v.is_data.konversi.$model"
            :state="checkIfValid('konversi')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Harga 1 <span class="text-danger">*</span> </template>
          <b-form-input
            type="number"
            v-model="$v.is_data.harga_1.$model"
            :state="checkIfValid('harga_1')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Harga 2 <span class="text-danger">*</span> </template>
          <b-form-input
            type="number"
            v-model="$v.is_data.harga_2.$model"
            :state="checkIfValid('harga_2')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Harga 3 <span class="text-danger">*</span> </template>
          <b-form-input
            type="number"
            v-model="$v.is_data.harga_3.$model"
            :state="checkIfValid('harga_3')"
          ></b-form-input>
        </b-form-group>
        <b-button class="my-3" variant="info" @click="simpanSatuanBarang">
          Konfirmasi
        </b-button>
      </div>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-komposisi')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEditKomposisi",
  props: ["fileName", "data_edit", "option_satuan_barang"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_satuan_barang_id: { required },
      is_satuan_utama: { required },
      konversi: { required },
      harga_1: { required },
      harga_2: { required },
      harga_3: { required },
    },
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    tambahDataSatuan(key, add = false) {
      if (key == "satuan") {
        this.satuan_barang_obj = {
          is_satuan_utama: false,
          konversi: 0,
          harga_1: 0,
          harga_2: 0,
          harga_3: 0,
          ms_satuan_barang_id: "",
        };
        if (this.is_data.length == 0) {
          this.is_data.pool_satuan_barang.push(this.satuan_barang_obj);
        } else {
          if (add) {
            this.is_data.pool_satuan_barang.push(this.satuan_barang_obj);
          }
        }
      }
      if (key == "komposisi") {
        this.komposisi_obj = {
          ms_komposisi_barang_id: "",
        };
        if (this.is_data.length == 0) {
          this.is_data.pool_satuan_barang.push(this.komposisi_obj);
        } else {
          if (add) {
            this.is_data.pool_satuan_barang.push(this.komposisi_obj);
          }
        }
      }
    },
    editDataSatuan(index) {
      this.is_data.pool_satuan_barang.splice(index, 1);
    },
    hapusDataSatuan(index) {
      this.is_data.pool_satuan_barang.splice(index, 1);
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.is_data.id = vm.is_data.pool_satuan_barang_id
      console.log(vm.is_data);
      vm.$axios
        .post(`/pool_satuan_barang/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-satuan");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    // simpan satuan barang
    simpanSatuanBarang() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-satuan");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    // simpan komposisi barang
    simpanKomposisiBarang() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-satuan");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        ms_satuan_barang_id: "",
        is_satuan_utama: false,
        konversi: null,
        harga_1: null,
        harga_2: null,
        harga_3: null,
      };
    },
  },
};
</script>
