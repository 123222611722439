<template>
  <div>
    <b-modal
      id="modal-input-komposisi"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <div v-if="is_data">
        <b-form-group label-cols-md="3">
          <template v-slot:label> Komposisi <span class="text-danger">*</span> </template>
          <b-form-select
            v-model="$v.is_data.ms_komposisi_barang_id.$model"
            :options="option_komposisi_barang"
            :state="checkIfValid('ms_komposisi_barang_id')"
          ></b-form-select>
        </b-form-group>
      </div>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-komposisi')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalInputKomposisi",
  props: ["fileName", "komposisi_barang", "option_komposisi_barang"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: {
        ms_barang_id: this.komposisi_barang.barang_id,
        ms_komposisi_barang_id: "",
      },
      file_name: this.fileName,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("pool_")
        ? this.file_name.replace("pool_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_komposisi_barang_id: { required },
    },
  },
  methods: {
    checkIfValid(fieldName, childField = "") {
      const field = this.$v.is_data[fieldName];
      if (childField) {
        for (let i = 0; i < field.length; i++) {
          const el = field[i];
          if (el[childField].$dirty) {
            return null;
          }
        }
      } else {
        if (!field.$dirty) {
          return null;
        }
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log("reg", vm.is_data);
      vm.is_data.ms_barang_id = this.komposisi_barang.barang_id;
      if (!vm.is_data.ms_barang_id) {
        vm.$emit("errorFromChild", {
          variant: "danger",
          msg: _.toUpper("data kosong"),
          showing: true,
        });
        vm.button = "Simpan";
        vm.busy = false;
        return;
      }
      vm.$axios
        .post(`/${this.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-input-komposisi");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        ms_barang_id: "",
        ms_komposisi_barang_id: "",
      };
    },
  },
};
</script>
