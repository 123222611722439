<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Barang</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input-barang
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">
              {{ msg }}
            </b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger">
                        Hapus
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                    
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      @click="getDetailsById(item.item)"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                    
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-barang
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      :fileName="file_name"
      :option_kelas_terapi="option_kelas_terapi"
      :option_produsen="option_produsen"
      :option_jenis_barang="option_jenis_barang"
      :option_satuan_barang="option_satuan_barang"
      :option_komposisi_barang="option_komposisi_barang"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :fileName="file_name"
      :option_kelas_terapi="option_kelas_terapi"
      :option_produsen="option_produsen"
      :option_jenis_barang="option_jenis_barang"
      :option_satuan_barang="option_satuan_barang"
      :option_komposisi_barang="option_komposisi_barang"
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
  },
  name: "ms_barang",
  data() {
    return {
      file_name: "ms_barang",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_barang",
          label: "Nama Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_produk",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_kelas_terapi",
          label: "Kelas Terapi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "nama_golongan_barang",
        //   label: "Golongan Barang",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "nama_produsen",
          label: "Produsen",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_jenis_barang",
          label: "Jenis Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      option_kelas_terapi: [],
      // option_golongan_barang: [],
      option_produsen: [],
      option_jenis_barang: [],
      option_satuan_barang: [],
      option_komposisi_barang: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    role() {
      return this.$store.state.user_role
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let res = await vm.$axios.post(`/${vm.file_name}/list`, {
        tanpa_satuan_utama: true,
      });
      console.log(vm.file_name, res);

      vm.items = res.data.data;
      // let penampung = {}
      // for (let i = 0; i < res.data.data.length; i++) {
      //   const el_data = res.data.data[i];
      //   penampung = {}
      //   for (const key_field in vm.fields) {
      //     if (Object.hasOwnProperty.call(vm.fields, key_field)) {
      //       const el_field = vm.fields[key_field];
      //       penampung[el_field.key] = el_data[el_field.key]
      //     }
      //   }
      //   vm.items.push(penampung)
      // }
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.id = x.ms_barang_id;
      }
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;

      //kelas terapi
      let option_kelas_terapi = await vm.$axios.post("/ms_kelas_terapi/list");
      vm.option_kelas_terapi =
        option_kelas_terapi.data.status == 200
          ? option_kelas_terapi.data.data.map((x) => {
              return {...x, value: x.ms_kelas_terapi_id, text: x.nama_kelas_terapi };
            })
          : [];
      // //golongan barang
      // let option_golongan_barang = await vm.$axios.post("/ms_golongan_barang/list");
      // vm.option_golongan_barang =
      //   option_golongan_barang.data.status == 200
      //     ? option_golongan_barang.data.data.map((x) => {
      //         return { value: x.ms_golongan_barang_id, text: x.nama_golongan_barang };
      //       })
      //     : [];
      //produsen
      let option_produsen = await vm.$axios.post("/ms_produsen/list");
      vm.option_produsen =
        option_produsen.data.status == 200
          ? option_produsen.data.data.map((x) => {
              return {...x, value: x.ms_produsen_id, text: x.nama_produsen };
            })
          : [];
      //jenis barang
      let option_jenis_barang = await vm.$axios.post("/ms_jenis_barang/list");
      vm.option_jenis_barang =
        option_jenis_barang.data.status == 200
          ? option_jenis_barang.data.data.map((x) => {
              return {...x, value: x.ms_jenis_barang_id, text: x.nama_jenis_barang };
            })
          : [];
      //satuan barang
      let option_satuan_barang = await vm.$axios.post("/ms_satuan_barang/list");
      vm.option_satuan_barang =
        option_satuan_barang.data.status == 200
          ? option_satuan_barang.data.data.map((x) => {
              return {...x, value: x.ms_satuan_barang_id, text: x.nama_satuan };
            })
          : [];
      //komposisi barang
      let option_komposisi_barang = await vm.$axios.post("/ms_komposisi_barang/list");
      vm.option_komposisi_barang =
        option_komposisi_barang.data.status == 200
          ? option_komposisi_barang.data.data.map((x) => {
              return {...x, value: x.ms_komposisi_barang_id, text: x.nama_komposisi };
            })
          : [];
    },
    async getDetailsById(data) {
      const vm = this;
      try {
        const res = await vm.$axios(`/${vm.file_name}/details_by_id/${data.id}`);
        if (res.data.status == 200) {
          vm.is_data = res.data.data[0];
          vm.is_data.pool_satuan_barang.sort(
            (a, b) => b.is_satuan_utama - a.is_satuan_utama
          );
          // console.log("get", vm.is_data.pool_satuan_barang);
          vm.$bvModal.show("modal-edit-barang");
        }
      } catch (err) {
        console.log(err);
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
